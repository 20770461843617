import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import styles from "./index.module.scss"


class IndexTemplate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      excerpt: ''
    }
  }

  componentDidMount() {
    const { notations } = this.props.pageContext
    const post = this.props.data.markdownRemark
    const lang = post.fields.slug.split('/')[1]
    const index = Math.floor(Math.random() * notations.length)
    console.log(`index.${notations.length} ${index}`)
    const notation = notations[index]
    const max = lang === 'ja' ? 30 : 60
    let sentenceEnd = 0
    let end = 0
    for (let i = 0; i < max; i++) {
      if (i >= notation.excerpt.length) {
        break
      }
      const word = notation.excerpt.substring(i, i + 1)
      if (word === '.' || word === '。') {
        sentenceEnd = i
      }
      end += 1
    }
    if (sentenceEnd) {
      end = sentenceEnd
    }
    const excerpt = notation.excerpt.substring(0, end + 1)
    this.setState(
      {
        excerpt: excerpt
      }
    )
  }

  render() {
    const post = this.props.data.markdownRemark
    const lang = post.fields.slug.split('/')[1]
    const siteTitle = post.frontmatter.title
    const headerSubTitle = post.frontmatter.description
    const { altIndex } = this.props.pageContext
    const jaIndex = lang === 'ja' ? post : altIndex
    const enIndex = lang === 'en' ? post : altIndex
    const imageRoot = this.props.data.site.siteMetadata.imageRoot

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          lang={lang}
          slug={post.fields.slug}
        />
        {
          this.state.excerpt && (
            <div className={styles.main}>
              <div className={`${styles.excerpt} ${styles[lang]}`}>
                <div className={styles.text}>{this.state.excerpt}</div>
                <div className={styles.clip}></div>
              </div>
            </div>
          )
        }
        {
          this.state.excerpt && (
            <div className={styles.background} style={
              {
                backgroundImage: `url(${imageRoot}/iwakiri_labyrinth_7243_bw.png)`
              }
            }>
              <div className={styles.title}>
                <h2>{jaIndex.frontmatter.description}</h2>
                <h1>{jaIndex.frontmatter.title}</h1>
              </div>
              <div className={styles.sub}>
                <h2>{enIndex.frontmatter.title}</h2>
                <h3>{enIndex.frontmatter.description}</h3>
              </div>
              <div className={styles.nav}>
                <h2><a href={`/${lang}/worlds/`} title={translate('worlds.index', lang)}>{translate('worlds.index', lang)}</a></h2>
                <h3>{translate('worlds.index', lang === 'ja' ? 'en' : 'ja')}</h3>
                <h2><a href={`/${lang}/about/butoh-fu`} title={translate('about.butoh-fu', lang)}>{translate('about.butoh-fu', lang)}</a></h2>
                <h3>{translate('about.butoh-fu', lang === 'ja' ? 'en' : 'ja')}</h3>
                <h2><a href={`/${lang}/archives/`} title={translate('archives.index', lang)}>{translate('archives.index', lang)}</a></h2>
                <h3>{translate('archives.index', lang === 'ja' ? 'en' : 'ja')}</h3>
                <h2><a href={`/${lang}/donation/`} title={translate('donation.index', lang)}>{translate('donation.index', lang)}</a></h2>
                <h3>{translate('donation.index', lang === 'ja' ? 'en' : 'ja')}</h3>
              </div>
              <div className={styles.clip1}></div>
              <div className={styles.clip2}></div>
            </div>
          )
        }
      </Layout>
    )
  }
}

export default IndexTemplate

export const pageQuery = graphql`
  query IndexBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        imageRoot
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
